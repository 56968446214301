<template>
  <div class="question_container">
    <h2>{{ $t('questions.title', appNameParam) }}</h2>
    <div class="item" v-for="(item, index) in questionList" @click="handleItem(index)" :key="index">
      <div class="title">
        <span>{{ $t(item.question, appNameParam) }}</span>
        <img src="./arrow-right.png" class="arrow" alt="">
      </div>
      <div style="width: 100%;" v-if="current == index">
        <div class="answer" v-for="(answer, aIndex) in item.answer" :key="aIndex">
          <span style="font-weight: 700;" v-if="item.answer.length > 1">· </span>
          <span>{{ $t(answer, appNameParam) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'installQuestions',
  props: ['appName', 'mimetype'],
  data() {
    return {
      platform: 'ios',
      current: -1,
      iosQuestions: [
        {
          question: 'questions.ios.0.question',
          answer: [
            'questions.ios.0.answer.0'
          ]
        },
        {
          question: 'questions.ios.1.question',
          answer: [
            'questions.ios.1.answer.0'
          ]
        },
        {
          question: 'questions.ios.2.question',
          answer: [
            'questions.ios.2.answer.0'
          ]
        }, {
          question: 'questions.ios.3.question',
          answer: [
            'questions.ios.3.answer.0',
            'questions.ios.3.answer.1',
            'questions.ios.3.answer.2',
            'questions.ios.3.answer.3',
            'questions.ios.3.answer.4',
            'questions.ios.3.answer.5',
            'questions.ios.3.answer.6',
          ]
        }, {
          question: 'questions.ios.4.question',
          answer: [
            'questions.ios.4.answer.0'
          ]
        },
      ],
      android: [
        {
          question: 'questions.android.0.question',
          answer: [
            'questions.android.0.answer.0'
          ]
        }, {
          question: 'questions.android.1.question',
          answer: [
            'questions.android.1.answer.0'
          ]
        }, {
          question: 'questions.android.2.question',
          answer: [
            'questions.android.2.answer.0'
          ]
        }, {
          question: 'questions.android.3.question',
          answer: [
            'questions.android.3.answer.0'
          ]
        }, {
          question: 'questions.android.4.question',
          answer: [
            'questions.android.4.answer.0'
          ]
        }, {
          question: 'questions.android.5.question',
          answer: [
            'questions.android.5.answer.0'
          ]
        },
      ]
    }
  },
  methods: {
    handleItem(index) {
      if (this.current != index) {
        this.current = index
      } else {
        this.current = -1
      }
    }
  },
  computed: {
    appNameParam() {
      return { appName: this.appName }
    },
    questionList() {
      if (this.mimetype && this.mimetype.includes('ndro')) {
        return this.android
      } else {
        return this.iosQuestions
      }

    }
  }
}
</script>
<style scoped>
div {
  box-sizing: border-box;
}

.question_container {
  width: 100%;
  color: #0A0D26;
  padding: 30px 20px;
}

.item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.title {
  background-color: #f2f6f8;
  color: #333;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.answer {
  font-size: 14px;
  color: #555;
  padding: 10px;
}

.arrow {
  width: 15px;
  height: 15px;
}
</style>